import React from 'react';
import './TextBox.scss'

const TextBox = ({ text, label }) => {
  return(
    <div className="text">
      <span className="label">{label}</span>
      <div className="text-container">
        {text}
      </div>
    </div>
  )
}

export default TextBox;
export { TextBox }
