import React, { Component } from 'react';
import isEmail from 'validator/lib/isEmail';
import { auth } from '../../services';
import config from '../../services/config.json'
import { Button, FormGroup, FormControl, InputGroup } from 'react-bootstrap';
import "./Login.scss";
import logo from '../../assets/images/logo.png';
import MediaQuery from 'react-responsive';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';

const initialState = {
  email: {
    value: '',
    focus: false,
    error: false
  },
  password: {
    value: '',
    focus: false,
    error: false
  },
  error: "",
}

class Login extends Component {
  constructor(props){
    super(props);
    this.state= initialState;
    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
    this.formValidation = this.formValidation.bind(this);
  }

  componentDidMount() {
    if(auth.getAuthorizedUser()) {
      this.props.history.replace('/dashboard');
    }
  }

  handleChange(field, e) {
    let error = ((field === "email" && !isEmail(e.target.value)) || (field === "password" && !e.target.value));
    this.setState({ [field]: { value: e.target.value, focus: this.state[field].focus, error: error } });
  }

  login(event) {
    event.preventDefault();

    const user = {
      email: this.state.email.value,
      password: this.state.password.value
    };
    auth.login(user)
    .then(response => {
      user.roles = response.data.roles
      auth.setAuthorizedUser(user);
      this.setState({error: ""})
      this.props.history.replace('/dashboard');
    })
    .catch(error => {
      this.setState({error: "Email and password don't match"})
    })
  }

  formValidation() {
    return isEmail(this.state.email.value) && this.state.password.value
  }

  getClassName(field) {
    let className = (this.state[field] && this.state[field].focus) ? 'active' : 'inactive';
    className += (this.state[field] && this.state[field].error) ? ' error' : '';
    return className;
  }

  render() {
    loadProgressBar({ showSpinner: false }, auth.instance)
    return (
      <MediaQuery minWidth={720}>
        {(match) => (
          <div className={"login-page" + (match ? "" : " mobile")}>
            <div id="loadBar" className="loadBar"/>
            <iframe src={config.API_PATH + config.IFRAME_AUTHCHECK_PATH} height="0" frameBorder="0" title="save-cookies"/>
            <div className="background"></div>
            <div className="login-container">
              <div className={"logo-container" + (match ? " desktop" : "")}>
                <img src={logo} alt="Medable" className="logo" />
              </div>
              <div className={"login-user" + (match ? " desktop" : "")}>
                <form onSubmit={this.login}>
                  <FormGroup
                    controlId="formBasicText"
                    bsSize="large"
                    className={this.getClassName('email')}>
                    <InputGroup>
                      <InputGroup.Addon className="input-icon"><div className="user-icon"></div></InputGroup.Addon>
                      <FormControl
                        type="email"
                        placeholder="Username"
                        value={this.state.email.value}
                        onChange={e => this.handleChange("email", e)}
                        onFocus={() => this.setState({email: {focus: true, value: this.state.email.value, error: this.state.email.error}}) }
                        onBlur={() => this.setState({email: {focus: false, value: this.state.email.value, error: this.state.email.error}}) }
                      />
                    </InputGroup>
                    <span className="input-error-message">The email is not correct</span>
                  </FormGroup>
                  <FormGroup bsSize="large" className={this.getClassName('password')}>
                    <InputGroup>
                      <InputGroup.Addon className="input-icon"><div className="password-icon"></div></InputGroup.Addon>
                      <FormControl
                        type="password"
                        placeholder="Password"
                        value={this.state.password.value}
                        onChange={e => this.handleChange("password", e)}
                        onFocus={() => this.setState({password: {focus: true, value: this.state.password.value, error: this.state.password.error}}) }
                        onBlur={() => this.setState({password: {focus: false, value: this.state.password.value, error: this.state.password.error}}) }
                      />
                    </InputGroup>
                    <span className="input-error-message">The password is incorrect</span>
                  </FormGroup>
                  <Button
                    type="submit"
                    bsSize="large" block
                    disabled={!this.formValidation()}
                  >Sign in</Button>
                  <span className="error-message">{this.state.error}</span>
                </form>
              </div>
            </div>
          </div>
        )}
    </MediaQuery>)
  }
}

export default Login;
export { Login };
