import React, { Component } from 'react';
import _ from 'lodash';
import magnifyGlass from '../../../assets/images/magnifyGlass.svg';
import deleteShape from '../../../assets/images/deleteShape.svg';

import './Search.scss';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: this.props.expanded,
      value: ''
    }
    this.debouncedFunction = _.debounce(() => props.onChangeFunction(this.state.value), 400);
  }

  handleInput = () => event => {
    const { value } = event.target;
    this.setState({ value }, this.debouncedFunction());
  };

  render() {
    const { expanded, value } = this.state;
    if (!expanded) {
      return (
        <div className="circle-container" onClick={() => this.setState({ expanded: true })}>
          <img src={magnifyGlass} alt="" />
        </div>
      )
    }
    return (
      <div className="expanded-container">
        <div className="circle-container"><img src={magnifyGlass} alt="" /></div>
        <div className="input-container">
          <input
            onChange={this.handleInput()}
            value={value}
            type="text"
            placeholder="Search for anything"
          />
        </div>
        <div className="delete-shape">
          <div className="circle-container" onClick={e => this.setState({ value: "" }, this.debouncedFunction())}>
            <img src={deleteShape} alt="" />
          </div>
        </div>
      </div>
    )
  }
}

export default Search;
export { Search };
