import React from 'react';
import Header from './Header/Header'

import './HeaderAndFooter.scss'

const HeaderAndFooter = ({ children, backgroundColor, signOut, mobile }) => {
  return(
    <div className="header-and-footer">
      <Header mobile={mobile} signOut={signOut} />
      <div id="loadBar" className="loadBar"/>
      <div className="main-container" style={{ backgroundColor: backgroundColor }}>
        {children}
      </div>
      <div className="credits-container" style={{ backgroundColor: backgroundColor }}>
        <span className="copyright">© 2018 Medable. All Rights Reserved.</span>
      </div>
    </div>
  )
}

export default HeaderAndFooter;
export { HeaderAndFooter }
