import React from 'react';
import Select from 'react-select';
import { modalStyles, changeImageComponent, options } from './SortUtils';
import _ from 'lodash';
import './Sort.scss';

const Sort = ({ currentSort, handleChange }) => {
  return(
    <div className="sort-container">
      <span className="sort-text">
        Sorted by:
        <span className="current-sort">
          {_.isEmpty(currentSort) ? "No dates" : currentSort.label}
        </span>
      </span>
      <Select
        options={options}
        defaultValue={options[currentSort]}
        styles={modalStyles}
        components={{ changeImageComponent }}
        onChange={value => handleChange(value)}
      />
    </div>
  )
}

export default Sort;
export { Sort };
