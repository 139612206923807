import React from 'react';
import { Row, NotFound } from '../';
import './Table.scss'

const labels = ["Subject", "First Name", "Last Name", "Shipping Address", "Request Received", "Unit Shipped"]

const Table = ({ data, onChangeDate, onClickRow, isLoading }) => {
  return(
    <div className="table-container">
      <Row data={labels} label />
      {!isLoading && (data.length > 0 ?
        data.map(value => <Row data={value} key={value._id} onChangeDate={onChangeDate} onClickRow={onClickRow}/>) :
        <NotFound />)}
    </div>
  );
}

export default Table;
export { Table };
