import React from 'react';
import magnify from '../../../assets/images/magnify-big.svg';
import './NotFound.scss';

const NotFound = props => {
  return(
    <div className="not-found-container">
      <div className="magnify-container">
        <img src={magnify} alt="" />
      </div>
      <div className="not-found-title">
        No results found
      </div>
      <div className="not-found-subtitle">
        Maybe try a different keyword
      </div>
    </div>
  )
}

export default NotFound;
export { NotFound }
