import React from 'react';
import './Checkbox.scss'

const Checkbox = ({ current, options, label, selectCheckbox }) => {
  return(
    <div className="container">
      <span className="label">{label}</span>
      <div className="group-container">
        {options.map((value, index) => (
          <div className="checkbox-container" key={value}>

            <div className="checkbox"> {/* onClick={e => selectCheckbox(index)} */}
              <div className={options[current] === value ? "selected" : ""} />
            </div>
            <span className="checkbox-label">{value}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Checkbox;
export { Checkbox }
