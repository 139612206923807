import React from 'react';
import { components } from 'react-select';
import triangle from '../../../assets/images/downTriangle.svg';

const modalStyles = {
  indicatorSeparator: () => ({
    display: 'none'
  }),
  valueContainer: () => ({
    display: 'none'
  }),
  control: () => ({
    border: 'none',
    background: 'none'
  }),
  menu: provided => ({
    ...provided,
    width: '190px',
    right: '-15px',
  }),
  menuList: provided => ({
    ...provided,
    maxHeight: '160px'
  }),
  option: (provided, { isSelected, isDisabled, isFocused, isActive }) => ({
    ...provided,
    color: isSelected ? '#7791a2' : '#9b9b9b',
    backgroundColor: isDisabled
        ? null
        : isSelected ? '#fff' : isFocused ? 'rgba(119, 145, 162, 0.06)' : null,
    fontWeight: isSelected ? 'bold' : 'normal',
    outline: 'none'
  })
}

const changeImageComponent = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <img src={triangle} alt="" />
      </components.DropdownIndicator>
    )
  );
};

const options = [
  { value: 'noDates', label: 'No Dates' },
  { value: 'subject', label: 'Subject' },
  { value: 'firstName', label: 'First Name' },
  { value: 'lastName', label: 'Last Name' },
  { value: 'shippingAddress', label: 'Shipping Address' },
  { value: 'requestReceived', label: 'Request Received' },
  { value: 'unitShipped', label: 'Unit Shipped' }
]

export { modalStyles, changeImageComponent, options }
