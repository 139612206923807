import React, { Component } from 'react';
import DatePicker from "react-datepicker";

import './Date.scss'
import 'react-datepicker/dist/react-datepicker.css';

class DateComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      date: this.props.initialValue ? new Date(this.props.initialValue) : null
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    let { type } = this.props;
    this.setState({
      date: date
    }, () => this.props.onChangeDate(this.state.date, type));
  }

  render() {
    return (
      <div className="datepicker-container">
        <DatePicker
          disabled={this.props.disabled}
          selected={this.state.date}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export { DateComponent }
export default DateComponent;
