import React from 'react';
import './Input.scss'

const Input = props => {
  const { label, value } = props;

  return (
    <div className="input-container">
      <span className="label">{label}</span>
      <div
        className="input"
      >{ value }</div>
    </div>
  )
}

export { Input }
export default Input
