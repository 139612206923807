import React from 'react';
import { Button } from 'react-bootstrap';
import './Header.scss';
import logo from '../../../assets/images/logo.png';

const Header = props => {
  return (
    <div className={"header-component" + (props.mobile ? " mobile" : "")}>
      <div className="pull-left side-container">
        <img src={logo} alt="Medable" className="logo"/>
      </div>
      <div className="pull-right side-container">
        <Button className="sign-out" onClick={() => props.signOut()}>Sign out</Button>
      </div>
    </div>
  )
}

export default Header;
export { Header };
