import React from 'react';
import './Button.scss';

const Button = ({text, onClick}) => {
  return(
    <div
      className="button-container"
      onClick={e => onClick()}
    >
      <span className="button-text">{text}</span>
    </div>
  )
}

export { Button };
export default Button;
