import * as axios from 'axios';
import * as config from './config';

class Provider {
  constructor() {
    this.instance = axios.create({
        baseURL: config.API_PATH,
        headers: {
          'Content-Type': 'application/json',
          'Medable-Client-Key': config.MEDABLE_CLIENT_KEY
        },
        withCredentials: true
    });
  }

  getShippingForm(id) {
    const url = config.SHIPPING_FORM + id
    return this.instance.get(url);
  }

  getTasks(page = 1, term = null) {
    let url;
    if (term) {
      url = `${config.SEARCH_TASKS + term}?taskLimit=15&taskSkip=0`
    } else {
      url = `${config.TASKS_PATH}?taskLimit=15&taskSkip=${(page-1)*15}`
    }
    return this.instance.get(url)
  }

  updateUnitShippedDate(params) {
    return this.instance.post(config.UPDATE_DATE, params)
  }
}

let provider = new Provider();
export { provider };
