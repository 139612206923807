import React, { Component } from 'react';
import _ from 'lodash';
import Responsive from 'react-responsive';
import { Sort, Search, Table, TableMobile, SortMobile, Pagination } from '../../components/Dashboard'
import HeaderAndFooter from '../../components/HeaderAndFooter/HeaderAndFooter'
import { auth, provider } from '../../services';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import './Dashboard.scss'

const Desktop = props => <Responsive {...props} minWidth={720} />;
const Mobile = props => <Responsive {...props} maxWidth={719} />;

class Dashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: [],
      currentSort: { value: "noDates", label: "No Dates" },
      currentPage: 1,
      currentSearch: "",
      firstPage: 1,
      totalPages: 0,
      pending: true,
      currentForm: null
    }
    this.handleSort = this.handleSort.bind(this);
    this.updateDate = this.updateDate.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  componentDidMount() {
    const page = parseInt(this.props.location.search.split('=')[1]) || this.state.currentPage;
    provider.getTasks(page).then(resp => {
      this.setState({ data: resp.data.data.allTask, totalPages: Math.ceil(resp.data.data.countTask / 15), pending: false, currentPage: page })
    }).catch(e => { this.signOut() })
  }

  handleSort(currentSort) {
    let newData;
    if(currentSort.value === "noDates") {
      let nullValues = [];
      let notNullValues = [];
      this.state.data.map(value => {
        if (value.unitShipped) {
          notNullValues.push(value)
        } else {
          nullValues.push(value)
        }
        _.orderBy(notNullValues, 'subject', 'asc');
        _.orderBy(nullValues, 'subject', 'asc');
        return null
      })
      newData = [...nullValues, ...notNullValues];
    } else {
      newData = _.orderBy(this.state.data, currentSort.value, 'asc');
    }
    this.setState({ currentSort, data: newData })
  }

  updateDate(id, date, type, mobile = false) {
    if (mobile) {
      date = new Date(date);
      date.setDate(date.getDate() + 1);
    }
    provider.updateUnitShippedDate({ idShipped: id, [type]: date }).then(resp => {
      provider.getTasks(this.state.currentPage, this.state.currentSearch).then(res => {
        this.setState({ data: res.data.data.allTask, totalPages: Math.ceil(res.data.data.countTask / 15) })
      })
    })
  }

  handlePage(page, isMobile) {
    const { totalPages, firstPage } = this.state
    let newState = {
      currentPage: page
    }
    if (isMobile) {
      if (page >= firstPage+2 && page < totalPages ) {
        newState['firstPage'] = page - 2
      }
      if (page < firstPage && page > 0) {
        newState['firstPage'] = page
      }
      if (page === totalPages) {
        newState['firstPage'] = totalPages - 3
      }
    } else {
      if (page > firstPage+2 && page < totalPages ) {
        newState['firstPage'] = page - 3
      }
      if (page < firstPage && page > 0) {
        newState['firstPage'] = page
      }
      if (page === totalPages) {
        newState['firstPage'] = totalPages - 4
      }
    }
    provider.getTasks(page, this.state.currentSearch).then(resp => {
      newState.data = resp.data.data.allTask;
      newState.totalPages = Math.ceil(resp.data.data.countTask / 15);
      this.setState(newState)
    }).catch(e => this.signOut())
  }


  redirectForm = id => {
    this.props.history.replace(`/form/${id}?page=${this.state.currentPage}`)
  }

  handleSearch = term => {
    provider.getTasks(this.state.currentPage, term).then(resp => {
      this.setState({ data: resp.data.data.allTask, totalPages: Math.ceil(resp.data.data.countTask / 15), currentSearch: term })
    })
  }

  signOut() {
    auth.logoutAuthorizedUser();
    this.props.history.replace('/');
  }

  render() {
    const { currentPage, totalPages, firstPage, currentSort, data, pending } = this.state;
    loadProgressBar({showSpinner: false, parent: '.loadBar' }, provider.instance)
    return(
      <div className="dashboard-page">
        <Desktop>
          <HeaderAndFooter backgroundColor="#f6f6f6" signOut={this.signOut}>
            <div className="navbar">
              <Search onChangeFunction={this.handleSearch}/>
              <Sort currentSort={currentSort} handleChange={this.handleSort}/>
            </div>
            <Table data={data} onChangeDate={this.updateDate} onClickRow={this.redirectForm} isLoading={pending}/>
            <Pagination currentPage={currentPage} totalPages={totalPages} firstPage={firstPage} onClick={this.handlePage}/>
          </HeaderAndFooter>
        </Desktop>
        <Mobile>
          <HeaderAndFooter mobile={true} backgroundColor="#f1f1f1" signOut={this.signOut}>
            <div className="mobile-navbar">
              <Search onChangeFunction={this.handleSearch} expanded/>
              <SortMobile currentSort={currentSort} handleChange={this.handleSort} />
            </div>
            <TableMobile data={data} onClickRow={this.redirectForm} onChangeDate={this.updateDate}/>
            <Pagination currentPage={currentPage} totalPages={totalPages} firstPage={firstPage} onClick={this.handlePage} mobile />
          </HeaderAndFooter>
        </Mobile>
      </div>
    )
  }
}

export { Dashboard }
