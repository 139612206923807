import React, { Component } from 'react';
import Dotdotdot from 'react-dotdotdot';
import button from '../../../assets/images/right-shape.svg';

const labels = [
  { value: 'subject', label: 'Subject' },
  { value: 'firstName', label: 'First Name' },
  { value: 'lastName', label: 'Last Name' },
  { value: 'shippingAddress', label: 'Shipping Address' },
  { value: 'requestReceived', label: 'Request Received' },
  { value: 'unitShipped', label: 'Unit Shipped' }
]

class TableMobile extends Component {
  constructor(props) {
    super(props);
    this.renderColumn = this.renderColumn.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.state = {
      currentPage: 0
    }
  }

  previousPage() {
    const prev = this.state.currentPage === 0 ? 5 : this.state.currentPage - 1;
    this.setState({ currentPage: prev });
  };

  nextPage() {
    const next =  this.state.currentPage === 5 ? 0 : this.state.currentPage + 1;;
    this.setState({ currentPage: next });
  };

  renderColumn(value) {
    const { currentPage } = this.state;
    const { onClickRow } = this.props;
    const currentValue = labels[currentPage].value;
    const dateToShow = currentValue === 'requestReceived' || currentValue === 'unitShipped' ?
    value[currentValue] ? new Date(value[currentValue]) : null : null
    if (dateToShow) {
      dateToShow.setDate(dateToShow.getDate() - 1)
    }
    return (
      <div className="mobile-cell" key={value._id}>
        <div className="arrow-container" onClick={e => onClickRow(value._id)} />
        {currentValue === 'requestReceived' || currentValue === 'unitShipped' ?
        <div className="datepicker-container">
          <input
            type="date"
            value={dateToShow ? dateToShow.toISOString().slice(0,10) : ""}
            onChange={e => this.props.onChangeDate(value._id, new Date(e.target.value), currentValue, true)}
          />
        </div>
        : <span className="value">
            <Dotdotdot clamp={1}>
              {value[currentValue]}
            </Dotdotdot>
          </span>}
        <div className="arrow-container" onClick={e => onClickRow(value._id)}>
          <img src={button} alt="" />
        </div>
      </div>
    )
  }

  render() {
    const { data } = this.props;
    return(
      <div className="table-mobile-container">
        <div className="title-header">
          <div className="left-button table-button" onClick={() => this.previousPage()} />
          <div className="table-title">
            {labels[this.state.currentPage].label}
          </div>
          <div className="right-button table-button" onClick={() => this.nextPage()} />
        </div>
        {data.length > 0 ? data.map(value => this.renderColumn(value)) : ""}
      </div>
    )
  }
}

export default TableMobile;
export { TableMobile };
