import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Dashboard, Form, Login } from './pages';
import './App.css';

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Route exact path="/" component={Login} />
          <ProtectedRoute path="/form/:user" component={Form} />
          <ProtectedRoute path="/dashboard" component={Dashboard} />
        </div>
      </Router>
    );
  }
}

export default App;
