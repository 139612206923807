import React from 'react';
import './Pagination.scss';

const Pagination = ({ currentPage, firstPage, totalPages, onClick, mobile }) => {
  const maxPages = mobile ? 4 : 5
  let pages = [];
  let needDots;
  if (totalPages <= maxPages) {
    for (var i = 1; i <= totalPages; i++) {
      pages.push(i)
    }
    needDots = false;
  } else {
    if(mobile){
      pages = [firstPage, firstPage+1, firstPage+2, totalPages]
    } else {
      pages = [firstPage, firstPage+1, firstPage+2, firstPage+3, totalPages]
    }
    needDots = (firstPage + maxPages - 1 !== totalPages);
  }
  if (totalPages > 0) {
    return(
      <div className="pagination-container">
        <div className="page-button" onClick={e => currentPage > 1 && onClick(currentPage - 1, mobile) }>Prev</div>
        {pages.map((value, index) => {
          if (index === maxPages-1 && needDots) {
            return(
              <div key={value} className="button-with-dots">
                <div className="dots">
                  <div className="dot"/>
                  <div className="dot"/>
                  <div className="dot"/>
                </div>
                <div className={"page-button" + (currentPage === value ? " current" : "")} key={value} onClick={e => onClick(value, mobile)}>
                  {value}
                </div>
              </div>
            )
          }
          return(
            <div className={"page-button" + (currentPage === value ? " current" : "")} key={value} onClick={e => onClick(value, mobile)}>
              {value}
            </div>
          )})}
        <div className="page-button" onClick={e => currentPage < totalPages && onClick(currentPage + 1, mobile)}>Next</div>
      </div>
    );
  }
  return null
}

export default Pagination;
export { Pagination };
