import React from 'react';
import Dotdotdot from 'react-dotdotdot';
import './Row.scss';

import { DateComponent } from '../Date/Date'

const labels = [
  'subject',
  'firstName',
  'lastName',
  'shippingAddress',
  'requestReceived',
  'unitShipped'
]

const Row = ({ data, label, onChangeDate, onClickRow }) => {
  const iterator = label ? data : labels;

  const handleChangeDate = (newDate, type) => {
    onChangeDate(data._id, newDate, type);
  }

  return(
    <div className={"row-container" + (label ? " labels" : " data")}>
      {iterator.map(value =>
        <div key={value} className={"cell " + ((value === "shippingAddress" || value === "Shipping Address") ? " large" : "")}>
          {label ? value : value === 'requestReceived' || value === 'unitShipped' ?
          <DateComponent onChangeDate={handleChangeDate} type={value} initialValue={data[value]}/>
          :
          <Dotdotdot clamp={2}>
              {data[value]}
          </Dotdotdot>
          }
        </div>
      )}
      <div className={label ? "space" : "row-button-container"} onClick={e => onClickRow(data._id)}>
        {!label && <img className="container-img-hover"  alt="" />}
      </div>
    </div>
  );
}

export default Row;
export { Row };
