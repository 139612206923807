import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import HeaderAndFooter from '../../components/HeaderAndFooter/HeaderAndFooter';
import { Input, Checkbox, Button, TextBox } from '../../components/Form';
import { auth, provider } from '../../services';
import './Form.scss';

class Form extends Component {
  constructor(props) {
    super(props)
    this.selectCheckbox = this.selectCheckbox.bind(this);
    this.signOut = this.signOut.bind(this);
    this.state = {
      checkbox: {
        options: ["I","II", "III","IV", "V", "VI"],
        current: -1
      },
      currentForm: {},
      page: 1
    }
  }

  componentDidMount() {
    const id = this.props.desktop ? this.props.currentForm : this.props.match.params.user;
    const page = this.props.desktop ? 1 : this.props.location.search.split('=')[1];
    provider.getShippingForm(id).then(resp => {
      this.setState({ currentForm: resp.data.data, page })
    }).catch(e => this.handleExit())

	};

  selectCheckbox(current) {
    const options = this.state.checkbox.options
    this.setState({ checkbox: { options, current } })

  }

  signOut() {
    auth.logoutAuthorizedUser();
    this.props.history.replace('/');
  }

  handleExit = () => {
    this.props.desktop ? this.props.closeModal() : this.props.history.replace(`/dashboard?page=${this.state.page}`);
  }

  render() {
    const { checkbox: { options }, currentForm } = this.state;

    let dateFormat
    if (currentForm["date"]) {
      let date = currentForm["date"].split('-');
      dateFormat =  date[1] +'/'+ date[2] +'/'+ date[0]
    } else {
      dateFormat = currentForm["date"];
    }

    const content = <div className="form-content-container">
      <div className="title-container">
        <div className="title-aux" onClick={e => this.handleExit()}>
          <div className="cross"> </div>
        </div>
        <div><span className="title-text">Shipping Form</span></div>
        <div className="image"></div>
      </div>
      <div className="content">
        <div className="row left">
          <Input label="Patient First Name:" value={currentForm["firstName"]}/>
          <Input label="Patient Last Name:" value={currentForm["lastName"]}/>
          <Input label="Sex:" value={currentForm["sex"]}/>
          <Input label="Year of Birth:" value={currentForm["yearOfBirth"]}/>
          <Input label="Date of Randomization: " value={currentForm["dateOfRandomization"]}/>
          <Input label="Street Address: " value={currentForm["streetAddress"]}/>
          <Input label="City: " value={currentForm["city"]}/>
          <Input label="State: " value={currentForm["state"]}/>
          <Input label="Zip Code: " value={currentForm["zipCode"]}/>
          <Input label="Patient Mobile Phone:" value={currentForm["phone"]}/>
          <Input label="Patient Secondary Phone (Optional):" value={currentForm["phoneSecondary"]}/>
          <Input label="Patient Email:" value={currentForm["email"]}/>
        </div>
        <div className="row right">
          <Checkbox options={options} current={currentForm["skinType"]} label="Patient Skin Type" selectCheckbox={this.selectCheckbox}/>
          <Input label="Language: " value={currentForm["language"]}/>
          <Input label="Prescriber Name" value={currentForm["signature"]}/>
          <Input label="Date:" value={dateFormat}/>
          <Input label="Dosing Protocol: " value={currentForm["dosingProtocol"]}/>
          {
            (currentForm["dosingProtocol"] === "Custom protocol") ?
            [
              <Input label="Prescriber phone:" value={currentForm["prescriberPhone"]}/>,
              <Input label="Prescriber secondary phone (optional):" value={currentForm["prescriberSecondaryPhone"]}/>
            ]
            :
              [
                <Input label="Areas to treat:" value={currentForm["areasToTreat"]}/>,
                <Input label="Areas to cover:" value={currentForm["areasToCover"]}/>,
                <TextBox label="Special Instructions: " text={currentForm["specialInstruc"]} />
              ]
          }
          <Input label="Daavlin to educate the patient on home phototherapy? " value={currentForm["educate"]}/>
        </div>
      </div>
      <div className="footer">
        <Button text="Back" onClick={() => this.handleExit()} />
      </div>
    </div>


    return(
      <MediaQuery minWidth={720}>
        {isDesktop => (
          <div className={"form-container" + (isDesktop ? "" : " mobile")}>
          <HeaderAndFooter backgroundColor="#fbfbfb" signOut={this.signOut}>
              {content}
            </HeaderAndFooter>
          </div>
        )}
      </MediaQuery>
    )
  }
}

export default Form;
export { Form }
