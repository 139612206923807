import React from 'react';
import { options } from './SortUtils';
import './Sort.scss';

const SortMobile = ({ currentSort, handleChange }) => {
  return(
    <div className="sort-container mobile">
      <span className="sort-text">
        Sorted by:
      </span>
      <select onChange={e => {
        let sort;
        options.map(opt => {
          if (opt.value === e.target.value) {
            sort = opt;
          }
          return null;
        })
        handleChange(sort)
      }}>
        {options.map(opt => (
          <option key={opt.value} value={opt.value}>{opt.label}</option>
        ))}
      </select>
    </div>
  )
}

export default SortMobile;
export { SortMobile };
